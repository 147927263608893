import React from "react"
import Seo from "../components/Seo"
import kontakt from "../assets/images/mann.png"
import Layout from "../components/Layout"

const contact = () => {
  return (
    <Layout>
      <Seo title="Krefeld Webentwicklung Kontakt" />
      <div>
        <div class=" text-gray-600 px-6 lg:px-8 pt-12 pb-20">
          <div class="max-w-screen-xl mx-auto">
            <img src={kontakt} alt="Kontakt Medienwerkstatt Krefeld" />
          </div>

          <div class="max-w-screen-sm mx-auto mt-8 lg:mt-12">
            <h2 class="text-2xl sm:text-3xl md:text-4xl xl:text-5xl text-MedienwerkstattBlue font-medium mb-4 lg:mb-6">
              Nehmen Sie Kontakt zu uns auf!
            </h2>
            <p class="md:text-lg text-MedienwerkstattDarkBlue ">
              Wir freuen uns und beantworten zeitnah Ihre Mail
            </p>
            <form
              method="post"
              action="https://getform.io/f/2c6d9fc6-2d32-4c78-b51f-83d0e9373b20"
            >
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <input
                  type="text"
                  class="bg-white w-full border border-gray-300 rounded-md py-3 px-3 text-MedienwerkstattBlue "
                  placeholder="Vorname"
                />
                <input
                  type="text"
                  class="bg-white w-full border border-gray-300 rounded-md py-3 px-3 text-MedienwerkstattBlue "
                  placeholder="Nachname"
                />
              </div>
              <div>
                <input
                  type="text"
                  class="bg-white w-full border border-gray-300 rounded-md py-3 px-3"
                  placeholder="Firma/Verein/Privat"
                />
              </div>
              <div>
                <input
                  type="email"
                  class="bg-white w-full border border-gray-300 rounded-md py-3 px-3 text-MedienwerkstattBlue "
                  placeholder="Email"
                />
              </div>
              <div>
                <textarea
                  rows="10"
                  class="bg-white w-full border border-gray-300 rounded-md py-3 px-3"
                  placeholder="Nachricht"
                ></textarea>
              </div>
              <div>
                <button class=" bg-MedienwerkstattBlue font-semibold rounded-lg py-4 px-5 lg:px-8 text-MedienwerkstattRot mt-6">
                  Absenden
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default contact
